import React, { Dispatch, SetStateAction, useState } from 'react';
import { ServicesType } from '../../types/ServiceInstance';
import { Button, ButtonGroup, Card, Grid, Icon, Popup } from 'semantic-ui-react';
import ServiceAddOns from './ServiceAddOns';
import { ServiceAddOn } from '../../types/Service';
import { carouselCart, cart, menuPage } from '../../../utils';
import { useCart } from '../../contexts/cart/CartContext';
import DeleteConfirmationModal from '../DeleteConfirmationModal';
import { handleGetQuantity } from '../../services/OrderStatus';

interface MenuItemCardProps {
    menuItem: ServicesType;
    addToCart?: (menuItem: ServicesType, selectedAddOns: ServiceAddOn[]) => void;
    source: string;
    quantity?: number;
    setCartItems?: Dispatch<SetStateAction<ServicesType[]>>;
    setSingleItemSelectedAddon?: Dispatch<SetStateAction<ServiceAddOn[]>>;
    SingleItemQuantity?: number;
    setSingleItemQuantity?: Dispatch<SetStateAction<number>>;
    handleRedirectToMealSelection?: () => void;
}

const MenuItemCard: React.FC<MenuItemCardProps> = ({ menuItem, addToCart, source, quantity, setCartItems, setSingleItemSelectedAddon, SingleItemQuantity, setSingleItemQuantity, handleRedirectToMealSelection }) => {
    const [selectedAddOns, setSelectedAddOns] = useState<ServiceAddOn[]>([]);
    const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] = useState<boolean>(false);
    const [pendingAction, setPendingAction] = useState<(() => void) | null>(null);
    const { mealItems, updateCart, removeFromCart, clearCart } = useCart();


    const handleAddToCart = (menuItem: ServicesType) => {
        addToCart && addToCart(menuItem, selectedAddOns);
    };

    const handleChangeQuantity = (value: number) => {
        if (source === cart) {
            // if the SingleItemQuantity is 1 and if the user tries to decrease the quantity we will show them a confirmation modal
            if (SingleItemQuantity === 1 && value === -1) {
                setOpenDeleteConfirmationModal(true);
                setPendingAction(() => () => {
                    setSingleItemQuantity && setSingleItemQuantity((prev) => prev + value);
                    handleRedirectToMealSelection && handleRedirectToMealSelection();
                });
            } else {
                setSingleItemQuantity && setSingleItemQuantity((prev) => prev + value);
            }
        } else {
            const item = mealItems.find((item) => String(item.serviceInstanceId) === String(menuItem._id));
            if (item) {
                const newQuantity = item.quantity + value;
                if (newQuantity === 0) {
                    setOpenDeleteConfirmationModal(true);
                    setPendingAction(() => () => {
                        // if the item is the only item in the cart we will clear the cart or else we will remove the item from the cart
                        if (mealItems.length === 1) {
                            clearCart();
                            setCartItems && setCartItems([]);
                        } else {
                            removeFromCart(item.serviceInstanceId);
                            setCartItems && setCartItems((prev) => prev.filter((cartItem) => String(cartItem._id) !== String(menuItem._id)));
                        }
                    });
                } else {
                    item.quantity = newQuantity;
                    updateCart([...mealItems]);
                }
            }
        }
    };

    const onClose = (): void => {
        setOpenDeleteConfirmationModal(false);
        setPendingAction(null);
    };

    const onConfirm = (): void => {
        if (pendingAction) {
            pendingAction();
        }
        setOpenDeleteConfirmationModal(false);
        setPendingAction(null);
    }

    const itemAlreadyExistInCart = () => {
        return mealItems.some((item) => String(item.serviceInstanceId) === String(menuItem._id));
    }

    return (
        <Card style={{ border: '0.5px solid black', width: '500px', display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Grid style={{ flexGrow: 1 }}>
                <Grid.Row style={{ padding: '20px', fontSize: '20px' }}>
                    <Grid.Column width={9} style={{ padding: '10px' }}>
                        <Card.Content>
                            <Card.Description style={{ fontWeight: '600', marginTop: '10px', fontSize: '19px' }}>
                                {menuItem.name}
                            </Card.Description>
                            <Card.Description style={{ marginTop: '10px', fontSize: '15px' }}>
                                {menuItem.shortDescription}
                            </Card.Description>
                            <Card.Description style={{ fontWeight: '600', marginTop: '10px', fontSize: '15px' }}>
                                {menuItem.menuCategory && menuItem.menuCategory.slice(0, 1).toUpperCase() + menuItem.menuCategory.slice(1) || " "}
                            </Card.Description>
                            <Card.Description style={{ marginTop: '10px' }}>
                                {menuItem &&
                                    menuItem.defaults &&
                                    menuItem.defaults.externalCost &&
                                    Number(menuItem.defaults.externalCost) !== 0
                                    ? `$${menuItem.defaults.externalCost}`
                                    : ''}
                            </Card.Description>
                            <Card.Description style={{ fontWeight: '600', marginTop: '10px', fontSize: '15px' }}>
                                {menuItem.DocId && menuItem.docObject && <>
                                    <p >Attached Document: <a href={menuItem.docObject.attachedFiles && menuItem.docObject.attachedFiles[0].attachedLink} target="_blank" rel="noopener noreferrer"><span style={{ fontSize: '1em', fontWeight: 'bold' }}>{menuItem.docObject.Name}</span></a></p>
                                </>}
                            </Card.Description>
                        </Card.Content>
                    </Grid.Column>
                    <Grid.Column width={7} style={{ padding: '10px', marginTop: '10px' }}>
                        {menuItem && menuItem.signedUrl ? (
                            <img src={menuItem.signedUrl} width={'100%'} height={'80px'} />
                        ) : (
                            <Icon name="images" size="huge" />
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Grid columns={2} style={{ marginTop: "auto", marginBottom: "-1px", alignItems: itemAlreadyExistInCart() ? 'flex-end' : "flex-start" }}>
                <Grid.Column width={(source === cart || source === carouselCart) ? 10 : 12}>
                    <div className='service-add-on'>
                        {
                            menuItem && menuItem.ServiceAddOns && menuItem.ServiceAddOns.length > 0 && source ?
                                <ServiceAddOns menuId={menuItem._id} source={source} serviceAddOns={menuItem.ServiceAddOns} setSelectedAddons={source === cart && setSingleItemSelectedAddon ? setSingleItemSelectedAddon : setSelectedAddOns} /> : "No Addons Available."
                        }
                    </div>
                </Grid.Column>
                <Grid.Column width={(source === cart || source === carouselCart) ? 6 : 4}>
                    <div>
                        {
                            (source === cart || source === carouselCart || itemAlreadyExistInCart()) ?
                                <ButtonGroup style={{ marginBottom: '10px', marginLeft: source === menuPage ? "-80px" : "-30px", marginTop: "10px" }}>
                                    <Button icon='minus' onClick={() => handleChangeQuantity(-1)} />
                                    <Button content={source === cart ? SingleItemQuantity : source === menuPage ? handleGetQuantity(mealItems, menuItem._id) : quantity} style={{ width: "1px" }} />
                                    <Button icon='plus' onClick={() => handleChangeQuantity(1)} />
                                </ButtonGroup> :
                                <Popup
                                    trigger={<Icon name='cart' size='big' style={{ cursor: "pointer", margin: "10px" }} onClick={() => handleAddToCart(menuItem)} />}
                                    content={
                                        <div>
                                            <p>Add to cart</p>
                                        </div>
                                    }
                                    on='hover'
                                    position='top right'
                                />
                        }
                    </div>
                </Grid.Column>
            </Grid>
            <DeleteConfirmationModal
                open={openDeleteConfirmationModal}
                onClose={onClose}
                messageToDisplay='By decreasing the quantity to zero the item will be removed from the cart, Are you sure you want to remove it?'
                onConfirm={onConfirm}
            />
        </Card>
    );
};

export default MenuItemCard;
